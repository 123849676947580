<template>
    <div id="app">
        <v-app id="inspire">
            <AppBar class="shrink" @tokenChange-event="tokenChangeEventHandler" :user.sync="user" v-if="appBarActive" />
            <v-card height="100%">
                <router-view />
            </v-card>
            <Footer/>
        </v-app>
    </div>
</template>
<style>
    #inspire {
        height: 100vh;
    }
</style>
<script>
    /* eslint-disable */
    import jwt_decode from "jwt-decode";
    import dayjs from 'dayjs';

    import AppBar from '@/components/AppBar'
    import Footer from '@/components/Footer';

    export default {
        name: 'App',
        data: () => ({
            user: null,
            appBarActive: true, 
        }
        ),
        components: {
            AppBar, Footer
        },

        mounted() {
            this.$root.$on('disable-appBar-event', () => {
                console.log('**** Disable AppBar ****')
                this.appBarActive = false
            })

            this.$root.$on('enable-appBar-event', () => {
                console.log('**** Enable AppBar ****')
                this.appBarActive = true
            })

            this.$root.$on('auth-token-event', () => {
                this.user = this.getUserFromToken();
            })
            this.user = this.getUserFromToken();
        },

        methods: {
            logoutEventHandler() {
                console.log('exit')
                //do your stuff here.
            },
            getUserFromToken() {
                let authTokens = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;

                if (authTokens) {

                    //this.$root.$emit('tokenChangeEvent', 'Foo')
                    //console.log(`Access Token: ${authTokens.accessToken}`);

                    var decodedToken = jwt_decode(authTokens.accessToken);
                    //console.log('decodedToken: ', decodedToken);
                    this.$root.$emit('tokenChangeEvent', decodedToken)

                    //console.log(`givenname: ${decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"]}`);
                    //console.log(`surname: ${decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]}`);
                    //console.log(`role: ${decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]}`);

                    const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;

                    if (isExpired) {
                        console.log(`Access token expired ${(Date.now() - (decodedToken.exp * 1000)) / 1000} seconds ago (which doesn't really matter, we'll refresh it)`);
                    }

                    let roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
                    if (roles !== undefined && Array.isArray(roles)) {
                        if (roles.includes("Admin")) {
                            return {
                                admin: true,
                            }
                        }
                        else if (roles.includes("Examiner")) {

                            //console.log(decodedToken);

                            return {
                                examiner: true,
                                id: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
                                firstName: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"],
                                lastName: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"],
                                uniqueName: decodedToken["UniqueName"],
                            }
                        }
                    }
                }

                return null;
            },
            tokenChangeEventHandler() {
                this.user = this.getUserFromToken();
            },
        }
    };
</script>
