import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import api from './utils/dpeFinderAPI'

const state = {
    location: { lat: 44.2227778, lng: -93.9193056 }, //lat: 29.7837304, lng: -100.4458825
    functions: [],
    examiners: [],
    airports: [],
    selectedFunction: null,
    selectedExaminer: null,
    selectedAirport: null,
}

const actions = {
    loadExaminers: async function ({ commit }) {
        this.loading = true;
        
        await api.allExaminers()
            // eslint-disable-next-line
            .then(({ response, data }) => {
                if (typeof data !== 'undefined') {
                    if (data.length !== 0) {
                        commit('SAVE_EXAMINERS', data);
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => (this.loading = false))

    },
    loadFunctions: async function ({ commit }) {
        //console.log("*** Load Functions ***");

        this.loading = true;
        await api.functions()
            // eslint-disable-next-line
            .then(({ response, data }) => {
                if (typeof data !== 'undefined') {
                    if (data.length !== 0) {
                        commit('SAVE_FUNCTIONS', data);
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => (this.loading = false))
    },
    // eslint-disable-next-line
    loadAirports: async function ({ commit }) {
        //console.log("*** Fetch All Airports *** ");
        
        this.loading = true;
        await api.allAirports()
            // eslint-disable-next-line
            .then(({ response, data }) => {
                //console.log("*** Fetch Airports Results *** ", data);

                if (typeof data !== 'undefined' && data !== null) {
                    if (data.length !== 0) {
                        commit('SAVE_AIRPORTS', data);
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => (this.loading = false))
    },
    geolocate: async function ({ commit }) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(pos => {
                commit('location', { lat: pos.coords.latitude, lng: pos.coords.longitude })
                resolve({ lat: pos.coords.latitude, lng: pos.coords.longitude });
            }, err => {
                this.isLocationLoading = false;
                this.errorStr = err.message;
                reject(
                    {
                        lat: 29.7837304,
                        lng: -100.4458825
                    });
            })
        })
    },

    setSelectedFunction({ commit }, selectedFunction) {
        commit("SET_SELECTED_FUNCTION", selectedFunction);
    },
    setSelectedExaminer({ commit }, selectedExaminer) {
        commit("SET_SELECTED_EXAMINER", selectedExaminer);
    },
    setselectedAirport({ commit }, selectedAirport) {
        commit("SET_SELECTED_AIRPORT", selectedAirport);
    },
};

const mutations = {
    selection(state, selection) {
        if (selection !== undefined && selection !== null) {
            if (selection.functionCode !== undefined) {
                console.log("Search for Function: ", selection.functionCode);
                    state.selectedFunction = selection;
            }
            else if (selection.examinerId !== undefined) {
                console.log("Search for Examiner: ", selection.examinerId);
                state.selectedExaminer = selection;
            }
            else if (selection.airportId !== undefined) {
                console.log("Search for Airport: ", selection.airportId);
                state.selectedAirport = selection;
            }
        }
        else {
            console.log("*** selection: searchModel changed: ***", selection);
        }
    },
    location(state, location) {
        state.location = location
    }, 

    SAVE_FUNCTIONS(state, functions) {
        state.functions = functions;
    },
    SAVE_EXAMINERS(state, examiners) {
        state.examiners = examiners;
    },
    SAVE_AIRPORTS(state, airports) {
        state.airports = airports;
    },

    SET_SELECTED_FUNCTION(state, selectedFunction) { state.selectedFunction = selectedFunction; },
    SET_SELECTED_EXAMINER(state, selectedExaminer) { state.selectedExaminer = selectedExaminer; },
    SET_SELECTED_AIRPORT(state, selectedAirport) { state.selectedAirport = selectedAirport; },

}

const getters = {
    getSelectedFunction: state => state.selectedFunction,
    getSelectedExaminer: state => state.selectedExaminer,
    getSelectedAirport: state => state.selectedAirport
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})