<template>
    <v-footer height="auto" dark fixed width="auto">
        <v-row row class="text-caption">
            <v-col>
                <span v-bind="attrs" v-on="on">&copy; {{copyright}}</span>
            </v-col>
            
            <v-col>
                <router-link :to="{ name: 'privacy'}" target="_blank">
                    Privacy Statement
                </router-link>
            </v-col>
            
            <v-col right align="right">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">Version: {{version}} - ({{environment}})</span>
                    </template>
                    <span>API: {{apiUrl}}</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
    export default {
        name: 'FooterBar', 
        data: () => ({
            apiUrl: process.env.VUE_APP_API_URL,
            version: process.env.VUE_APP_VERSION,
            environment: process.env.VUE_APP_ENVIRONMENT,
            backend: process.env.VUE_APP_BACKEND_URL,
        }),

        computed: {
            // a computed getter
            copyright: function () {
                var currentTime = new Date()
                return `${currentTime.getFullYear()} All Rights Reserved`;
            }
        }
    }
</script>