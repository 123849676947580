import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    
    {
        path: '/simpleAdd',
        name: 'simpleAdd',
        component: () => import(/* webpackChunkName: "map" */ '../views/SimpleAddView.vue')
    },
    {
        path: '/',
        name: 'search',
        component: () => import(/* webpackChunkName: "map" */ '../views/MapSearchView.vue')
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue')
    },
    {
        path: '/examiners',
        name: 'examiners',
        props: true,
        component: () => import(/* webpackChunkName: "examiners" */ '../views/ExaminersView.vue')
    },
    {
        path: '/examiner/:uniqueName',
        name: 'examiner',
        props: true,
        component: () => import(/* webpackChunkName: "ExaminerDetails" */ '../views/ExaminerDetailsView.vue')
    },
    {
        path: '/examiner',
        name: 'examiner-admin',
        props: true,
        component: () => import(/* webpackChunkName: "ExaminerAdmin" */ '../views/ExaminerAdminView.vue')
    },
    {
        path: '/event',
        name: 'event',
        props: true,
        component: () => import(/* webpackChunkName: "Event" */ '../views/EventView.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        props: true,
        component: () => import(/* webpackChunkName: "Event" */ '../views/PrivacyView.vue')
    },
    
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

export default router
