<template>
    <v-app-bar color="grey lighten-3" app dark dense flat>

        <v-app-bar-title class="flex justify-stretch" dark>

            <v-autocomplete v-model="searchModel" light dense
                            :items="items"
                            :loading="loading"
                            :search-input.sync="searchText"
                            clearable
                            item-text="description"
                            item-value="value"
                            label="Search Examiners, Certificates, Type Rating or Airport"
                            placeholder="Start typing to Search"
                            return-object>

                <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>

                        <v-list-item-icon v-if="data.item.icon">
                            <v-icon>{{data.item.icon}}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-icon v-else>
                            <v-icon>mdi-security</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.description"></v-list-item-title>
                        </v-list-item-content>
                    </template>
                </template>
            </v-autocomplete>

        </v-app-bar-title>

        <v-btn color="primary" :to="{ name: 'search'}" class="mx-1">
            <v-icon>mdi-map-search</v-icon>
        </v-btn>
        <strong v-if="user && user.examiner" class="black--text mx-6" :to="{ name: 'examiner-admin'}">
            {{user.firstName}} {{user.lastName}}
        </strong>

        <v-btn v-if="user && user.admin" color="primary" :to="{ name: 'admin'}" class="mr-6">
            <v-icon>mdi-security</v-icon>
            <span>Admin</span>
        </v-btn>
        <v-btn v-if="user && user.examiner" color="primary" :to="{ name: 'examiner', params: { uniqueName: user.uniqueName } }" class="mr-6">
            <v-icon>mdi-calendar</v-icon>
        </v-btn>
        <v-btn v-if="user && user.examiner" color="primary" :to="{ name: 'examiner-admin'}" class="mr-6">
            <v-icon>mdi-account-cog</v-icon>
        </v-btn>
        <v-btn v-if="user" color="primary" v-on:click="logout()">
            <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
        <v-dialog v-if="!user" v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-login-variant</v-icon>
                    <span>Login</span>
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Examiner Login</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="email" label="Email*" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="password" label="Password*" type="password" required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" text @click="login">
                        Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>


<script lang="js">
    /* eslint-disable */
    import { defineComponent } from 'vue';
    import jwt_decode from "jwt-decode";
    import api from '../utils/dpeFinderAPI';
    import { mapGetters } from 'vuex'
    import { mapState } from "vuex";


    export default defineComponent({
        props: {
            user: {
                type: Object,
            },
        },

        data: () => ({
            dialog: false,
            email: "",
            password: "",
            loading: false,
            searchModel: null,
            searchText: null,
        }
        ),
        async created() {
            this.$store.dispatch('loadAirports');
            this.$store.dispatch('loadFunctions');
            this.$store.dispatch('loadExaminers');
        },
        async mounted() {
            //console.log("User: ", this.user)
        },
        watch: {
            searchModel: async function (selection) {
                //console.log("*** searchModel changed: ***", selection);
                this.selectedFunction = null;
                this.selectedExaminer = null;
                this.selectedAirport = null;

                if (selection !== undefined && selection !== null) {
                    if (selection.functionCode !== undefined) {
                        //console.log("Search for Function: ", selection.functionCode);
                        this.selectedFunction = selection.functionCode;
                        //console.log("this.selectedFunction Set: ", selection);
                        //state.selectedFunction = selection;
                    }
                    else if (selection.examinerId !== undefined) {
                        //console.log("Search for Examiner: ", selection);
                        this.selectedExaminer = selection.examinerId;
                        //console.log("this.selectedExaminer Set: ", selection);
                        //state.selectedExaminer = selection;
                    }
                    else if (selection.airportID !== undefined) {
                        //console.log("Search for Airport: ", selection.airportID);
                        this.selectedAirport = selection.airportID;
                        //console.log("this.selectedAirport Set: ", selection);
                        //state.selectedAirport = selection;
                    }

                    //console.log("this.$route.name: ", this.$route.name)
                    if (this.$route.name !== 'search') this.$router.push("/").catch(() => { console.log("just suppress it") });

                    //this.$router.push("/").catch(() => { console.log("just suppress it") });
                }
                else {
                    //console.log("*** Watch searchModel changed: ***", selection); // go get examiners in map view
                    //console.log("*** Go get examiners in map view: ***", selection);
                }
            },
        },
        computed: {
            selectedFunction: {
                get() { return this.$store.state.selectedFunction; },
                set(value) { this.$store.commit('SET_SELECTED_FUNCTION', value); },
            },
            selectedExaminer: {
                get() { return this.$store.state.selectedExaminer; },
                set(value) { this.$store.commit('SET_SELECTED_EXAMINER', value); },
            },
            selectedAirport: {
                get() { return this.$store.state.selectedAirport; },
                set(value) { this.$store.commit('SET_SELECTED_AIRPORT', value); },
            },
            ...mapState(['functions', 'examiners', 'airports']),

            items() {
                try {
                    //console.log("examiners: ", this.examiners);
                    let examiners = this.examiners.map(examiner => {
                        const icon = "mdi-account"
                        const description = examiner.firstName + " " + examiner.lastName;
                        const value = examiner.examinerID;
                        return Object.assign({}, examiner, { description, icon, value })
                    })

                    //console.log("functions: ", this.functions);
                    let functions = this.functions.map(func => {
                        //console.log("func.category: ", func)
                        const icon = func.functionCategory === 1 ? "mdi-certificate-outline" : "mdi-license"
                        let fullDescription = `${func.category} - ${func.detail}`;
                        if (func.typeRating !== null) {
                            fullDescription = `${func.category} - ${func.detail} - ${func.typeRating}`;
                        }
                        
                        const description = fullDescription.length > this.descriptionLimit ? fullDescription.slice(0, this.descriptionLimit) + '...' : fullDescription
                        const value = func.functionCode;
                        return Object.assign({}, func, { description, icon, value })
                    })

                    //console.log(" - airports: ");
                    //console.log(this.airports)
                    let airports = this.airports.map(airport => {
                        //console.log("** airport: ", airport);
                        const icon = "mdi-airport"
                        const fullDescription = airport.airportID + " - " + airport.description;
                        const description = fullDescription.length > this.descriptionLimit ? fullDescription.slice(0, this.descriptionLimit) + '...' : fullDescription
                        const value = airport.airportID;
                        return Object.assign({}, airport, { description, icon, value })
                    })

                    return functions.concat(examiners.concat(airports));
                    //return airports;
                }
                catch(ex) {
                    console.log("Error: ", ex);
                }
            },
        },
        methods: {
            login: async function () {
                this.dialog = false;
                await api.login(this.email, this.password)
                    .then(({ user, roles }) => {
                        this.$root.$emit('auth-token-event');

                        if (roles.includes("Admin")) {
                            if (this.$route.path !== '/admin') this.$router.push({ path: '/admin' });
                        }
                        else if (roles.includes("Examiner")) {
                            if (this.$route.path !== '/examiner') this.$router.push({ path: '/examiner' });; // -> /examiner
                        }
                    })
                    .finally(() => (this.loading = false))
                    ;
            },
            logout: async function () {
                let authTokens = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;
                if (authTokens !== undefined && authTokens.accessToken) {
                    var decodedToken = jwt_decode(authTokens.accessToken);
                }

                localStorage.removeItem('authTokens');
                this.$root.$emit('auth-token-event');

                this.$router.push({ path: '/' })
            },
        }
    });
</script>